import React from "react"

import Layout from '../../components/layout'
import Head from '../../components/head'
import TeamsTabbed from "../../components/teams_tabbed"

const Teams = () => {


    return (
        <Layout>
            <Head
                title="NFL Metric Comparison"
                pageDescription="Compare Any Two NFL Metrics with R-Squared"
            />
            <TeamsTabbed
                activeIndex={6}
            />
        </Layout>
    )
}

export default Teams

